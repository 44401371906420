<template>
  <div class="Awarp">
    <dashCard
      class="powerBox21"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('chuneng2_count','充电量统计', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
       
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>

      <div class="card-content1" style="display: flex; flex-direction: column">
        <!-- <div style="display: flex;align-items: center;">
          <el-select v-model="value" :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)" @change="onchange">
            <el-option
              v-for="item in optionsData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <a-select
          v-model="value"
          style="width: 220px"
          @change="onchange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in optionsData"
            :key="key"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <div style="display: flex;">
          <div class="display-item" style="display: flex; margin-top: 10px">
            <div v-if="searchObj.TYPE === '按年'">{{commonLangHandler('chuneng2_thisYear','今年总充电量', getZEdata)}}</div>
            <div v-if="searchObj.TYPE === '按月'">{{commonLangHandler('chuneng2_thisMonth','本月总充电量', getZEdata)}}</div>
            <div v-if="searchObj.TYPE === '按周'">{{commonLangHandler('chuneng2_thisWeek','本周总充电量', getZEdata)}}</div>
            <div v-if="searchObj.TYPE === '按天'">{{commonLangHandler('chuneng2_today','今天总充电量', getZEdata)}}</div>
            <div>{{ list.今日总充电量 }}<font>kWh</font></div>
            <div>
              <i
                class="lowcode"
                :class="
                  detailInfo.comparePercent1 >= 0
                    ? 'icon-shang shangColor'
                    : 'icon-xia xiaColor'
                "
              >
              </i>
              <font
                :class="
                  detailInfo.comparePercent1 >= 0 ? 'shangColor' : 'xiaColor'
                "
                >{{ list.比例 }} %</font
              >
              <font>{{commonLangHandler('chuneng2_yoy','较去年', getZEdata)}}</font>
            </div>
          </div>
        </div>

        <!-- <div class="display-item" v-if="!checked">
          <div v-if="searchObj.TYPE === '按年'">上年总费用</div>
          <div v-if="searchObj.TYPE === '按月'">上年本月总费用</div>
          <div v-if="searchObj.TYPE === '按周'">上年本周总费用</div>
          <div v-if="searchObj.TYPE === '按天'">上年今天总费用</div>
          <div>{{ toThousandsSeparator(detailInfo.cost2) }}<font>元</font></div>
          <div>
            <i class="lowcode"
               :class="detailInfo.comparePercent2 >= 0 ? 'icon-shang shangColor': 'icon-xia xiaColor'"> </i>
            <font :class="detailInfo.comparePercent2 >= 0 ? 'shangColor': 'xiaColor'">{{
                Math.abs(detailInfo.comparePercent2) || 0
              }} %</font>
            <font v-if="searchObj.TYPE === '按年'">较去年</font>
          </div>
        </div> -->
      </div>
      <div class="card-content2" style="height: calc(100vh)">
        <!-- <a-switch checked-children="同比" un-checked-children="同比" v-model="checked" @change="checkedChange"/> -->
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <!-- <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <powerBox21
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal> -->
  </div>
</template>
<script>
export default {
  name: "powerBox21",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.optionsdata();
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      checked: true,
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],

      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      list: [],
      value: "",
      optionsData: [],
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#3366FF", "#FF841F"],
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    checkedChange() {
      //console.log(this.checked);
      this.initEchart();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          queryType: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          queryType: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          queryType: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          queryType: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },

    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
    },
    // 获取下拉框数据
    optionsdata() {
      
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist";
      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
        type: "储能PCS",
      };
      this.$axios.post(url, data).then((res) => {
        // //console.log(res.data.data);
        this.optionsData = []
        let arr = [];
        console.log(res.data.data)
        if(res.data.data){
          res.data.data.forEach((item) => {
            let obj = {
              value: item.assetNumber,
              label: item.assetName,
            };
            arr.push(obj);
          })
        }
        
        
        this.optionsData = arr;
        if (this.optionsData.length > 0) {
         
          this.value = this.optionsData[0].value;
          this.getData();
        }else{
          
          this.value = ""
        }
        // //console.log(this.option)
      });
    },
    onchange(value) {
      //console.log(value);
      this.getData();
    },
    //每月报警预警数
    getData() {
      // this.detailInfo = {
      //     cost1: '51276.33',
      //     cost2: '5270.33',
      //     comparePercent1: -11.2,
      //     comparePercent2: 11.2,
      // }
      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
        queryType: "week",
        equipment: this.value,
      };
      Object.assign(data, this.transformSearch());

      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/charging/quantity",
        method: "post",
        data: data,
      })
        .then((res) => {
          //console.log(res);
          this.list = res.data.data;
          let result = res.data.data;
          var chart1 = {
            categoryData: result.date,
            seriesData: [],
          };
          // let arr1 = [123, 321, 123, 131, 231, 31, 123];
          // let arr2 = [122, 421, 223, 231, 211, 31, 423];
          chart1.seriesData.push(result.充电);
          chart1.seriesData.push(result.放电);
          chart1.seriesData.push(result.放电占比);
          //console.log(chart1);
          this.detailInfo.chart1 = chart1;
          let lang = sessionStorage.getItem("locale") || 'CH'
            if(lang=='EN'){
            
              for(let item of this.detailInfo.chart1.seriesData){
                  if(item.name =='充电'){
                      item.name = 'charge'
                  }
                  if(item.name =='放电'){
                      item.name = 'discharge'
                  }
              }
            }
          this.initEchart();
          this.$forceUpdate();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    initEchart() {
      let vm = this;
      var { categoryData, seriesData } = this.detailInfo.chart1;
      
      let arr2 = [];
      let arr = [];
      seriesData[0].forEach((item2) => {
        if (item2) {
          //console.log(item2);
          item2 = parseFloat(item2).toFixed(2);
        }
        arr.push(item2);
      });
      seriesData[1].forEach((item2) => {
        if (item2) {
          //console.log(item2);
          item2 = parseFloat(item2).toFixed(2);
        }
        arr2.push(item2);
      });
      console.log(this.detailInfo.chart1)
      //console.log(arr);
      var series = [];
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "2%",
          right: "0%",
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "10%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          name: "",
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: [{
          type: "value",
          name: "kWh",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
            formatter(v) {
              v = v.toString();
              if (v >= 100000000000) {
                return v.substring(0, 5) / 10 + "亿";
              } else if (v >= 10000000000) {
                return v.substring(0, 4) / 10 + "亿";
              } else if (v >= 1000000000) {
                return v.substring(0, 3) / 10 + "亿";
              } else if (v >= 100000000) {
                return v.substring(0, 2) / 10 + "亿";
              } else if (v >= 10000000) {
                return v.substring(0, 4) + "万";
              } else if (v >= 1000000) {
                return v.substring(0, 3) + "万";
              } else if (v >= 100000) {
                return v.substring(0, 2) + "万";
              } else if (v >= 10000) {
                return v.substring(0, 2) / 10 + "万";
              } else if (v >= 1000) {
                return v;
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 7) / 10 + "亿";
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 6) / 10 + "亿";
              } else if (v <= -10000000000) {
                return "-" + v.substring(1, 5) / 10 + "亿";
              } else if (v <= -1000000000) {
                return "-" + v.substring(1, 4) / 10 + "亿";
              } else if (v <= -100000000) {
                return "-" + v.substring(1, 3) / 10 + "亿";
              } else if (v <= -10000000) {
                return "-" + v.substring(1, 5) + "万";
              } else if (v <= -1000000) {
                return "-" + v.substring(1, 4) + "万";
              } else if (v <= -100000) {
                return "-" + v.substring(1, 3) + "万";
              } else if (v <= -10000) {
                return "-" + v.substring(1, 3) / 10 + "万";
              } else if (v <= -1000) {
                return v;
              } else {
                return v;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              // type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        {
            name: "",
            axisTick: {
              show: true,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              padding:[0,0,0,25]
            },
            axisLine: {
              lineStyle: {
                color: "white",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            
            // 控制数据参数最大值和最小值
            // interval: 400,
            
          },],
        // 控住柱状图样式
        series: [
          {
            name: sessionStorage.getItem("locale") == "EN"?"charge":"充电",
            type: "bar",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            barWidth: 10,
            data: arr,
            emphasis: {
              focus: "series",
            },
          },
          {
            name: sessionStorage.getItem("locale") == "EN"?"discharge":"放电",
            type: "bar",
            barWidth: 10,
            animationDurationUpdate: 2000,
            data: this.detailInfo.chart1.seriesData[1],
            animationEasingUpdate: "quinticInOut",
            emphasis: {
              focus: "series",
            },
          },
          {
            name: sessionStorage.getItem("locale") == "EN"?"efficiency":"效率",
            type: 'line',
            itemStyle: {
              color: 'rgba(105,252,189,1)'
            },
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: this.detailInfo.chart1.seriesData[2]
          }
        ],
        color: this.colorList,
      };
      if(this.searchObj.TYPE == '按天'){
          option.series[2] = []
      }else{
      }
      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.value = ""
    this.optionsData = []
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
    this.optionsdata();
  },
};
</script>

<style lang="less" scoped>
.powerBox21 {
  .card-content1 {
    padding-left: 0px;
    // width: 100%;

    .display-item {
      height: inherit;
      box-sizing: border-box;
      flex-direction: column;
      margin-right: 10px;
      background: #fafafa;
      padding: 10px;
      border-radius: 10px;
      div {
        &:nth-child(1) {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }

        &:nth-child(2) {
          font-size: 32px;
          font-weight: 700;
          line-height: 40px;

          font {
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 20px;
            margin-left: 6px;
          }
        }

        &:nth-child(3) {
          i {
            font-size: 12px;
          }

          font {
            font-size: 12px;
            font-weight: 400;
            color: #9f9f9f;
            line-height: 20px;
            margin-right: 6px;
          }

          .shangColor {
            color: #f53f3f;
          }

          .xiaColor {
            color: #0ca919;
          }
        }
      }
    }
  }

  .card-content2 {
    flex: 1;
    height: 100%;
    position: relative;

    .ant-switch {
      position: absolute;
      top: 10px;
      right: 1px;
      z-index: 1;
      background: #3366ff;
    }

    .ant-switch-checked {
      background: #dbdbdb;
    }

    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>